/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import Form from '../components/form';

const JoinPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            ctaText="Sign Up"
            ctaAnchor="#start"
            noSignUpForm
            hideLinks
            noNav
        >
            <SEO
                title="Join My Mailing List"
                description="The people on my mailing list are the first to hear about my new content. Join them by signing up now!"
            />
            <Section
                narrow
            >
                <h2>Join My Mailing List</h2>
                <p>
                    The people on my mailing list are the first to hear about my new content.
                    Join them by signing up now!
                </p>
                <Form
                    mailingList
                    buttonAsideText="No spam, only value."
                    buttonText="Sign up now"
                    pathname={location && location.pathname}
                />
            </Section>
        </Layout>
    );
};

JoinPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

JoinPage.defaultProps = {
    location: {},
};

export default JoinPage;
